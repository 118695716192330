
<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>
      
<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
export default {
  name: "GraficaVolumenesNsv",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],
        xAxis: {
          categories: [],
        },

        yAxis: {
          title: {
            text: "Cantidad de Barriles",
          },
        },

        exporting: {
          enabled: true,
        },

        title: {
          text: "CANTIDAD BARRILES TRANSPORTADOS NSV",
          align: "center",
          size: 5,
        },

        plotOptions: {
          column: {
            stacking: "normal",
          },
        },

        series: [
          {
            type: "column",
            data: [],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            stack: "male",
          },
          {
            type: "column",
            data: [],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            stack: "female",
          },
          /*  {
            type: "spline",
            name: "Promedio NSV",
            data: [],
            lineColor: Highcharts.getOptions().colors[4],
            color: "black",
            marker: {
              lineWidth: 1,
              lineColor: Highcharts.getOptions().colors[3],
              fillColor: "white",
            },
          },
          {
            type: "spline",
            name: "Promedio GSV",
            data: [],
            lineColor: Highcharts.getOptions().colors[3],
            color: "black",
            marker: {
              lineWidth: 1,
              lineColor: Highcharts.getOptions().colors[4],
              fillColor: "white",
            },
          }, */
        ],
        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/hidrocarburos/dashboard/graficaBarrilesTrans", {
          params: this.$parent.filter,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaVolumenes(me.respuesta);
        });
    },

    async graficaVolumenes(resp) {
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      let nsvCargue = [];
      let nsvDescargue = [];
      let mes = [];
      let promedioNsv = [];

      for (let i = 0; i < resp.length; i++) {
        if (resp[i].mes != 0 && resp[i].mes != null) {
          mes.push(meses[resp[i].mes - 1]);
          nsvDescargue.push(parseFloat(resp[i].descargueNsv));
          nsvCargue.push(parseFloat(resp[i].cargueNsv));
        }
        /* promedioNsv.push(
          (parseFloat(resp[i].sumaNsvDescargue) +
            (parseFloat(resp[i].sumaNsvDescargue) -
              parseFloat(resp[i].diferenciaNsv))) /
            2
        );*/
      }
      this.chartOptions.xAxis.categories = mes;
      this.chartOptions.series[0].data = nsvCargue;
      this.chartOptions.series[1].data = nsvDescargue;

      /*  this.chartOptions.series[4].data = promedioNsv;*/
      this.chartOptions.series[0].name = "NSV CARGUE";
      this.chartOptions.series[1].name = "NSV DESCARGUE";
    },
  },
  mounted() {
    this.getGrafica();
  },
};
</script>
