
<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>
      
<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
export default {
  name: "GraficaVehiculos",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        xAxis: {
          categories: [],
        },

        yAxis: {
          title: {
            text: "Cantidad de Vehículos",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "CANTIDAD DE VEHICULOS POR MES",
          align: "center",
        },

        series: [
          {
            type: "column",
            data: [],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/hidrocarburos/dashboard/graficaVehiculo", {
          params: this.$parent.filter,
        })
        .then(async (response) => {
          me.respuesta = response.data;          
          await me.graficaVehiculos(me.respuesta);
        });
    },

    async graficaVehiculos() {
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      let cantVehiculos = [];
      let mes = [];
      for (let i = 0; i < this.respuesta.length; i++) {
        if (this.respuesta[i].mes != 0 && this.respuesta[i].mes != null) {
          mes.push(meses[this.respuesta[i].mes - 1]);
          cantVehiculos.push(parseFloat(this.respuesta[i].cantidadVehiculos));
        }
      }
      this.chartOptions.xAxis.categories = mes;
      this.chartOptions.series[0].data = cantVehiculos;
      this.chartOptions.series[0].name = "N° Vehículos";
    },
  },

  mounted() {
    this.getGrafica();
  },
};
</script>
