<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-2">
                <h5 class="mb-0">Dashboard</h5>
              </div>
              <div class="col-sm-2">
                <select
                  class="form-control form-control-sm"
                  v-model="filter.año"
                  @change="getIndex()"
                >
                  <option
                    v-for="año in listasForms.años"
                    :key="año.numeracion"
                    :value="año.descripcion"
                    >{{ año.descripcion }}</option
                  >
                </select>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Dashboard</li>
                  <li class="breadcrumb-item active">Grafico General</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <GraficaVolumenesNsv ref="GraficaVolumenesNsv" />
              </div>
              <div class="col-md-6">
                <GraficaVolumenesGsv ref="GraficaVolumenesGsv" />
              </div>
            </div>
            <div class="card">
              <div class="card-header bg-frontera">
                <h5 class="card-title text-white">
                  <i class="fas fa-filter"></i><b> Filtros Graficas</b>
                </h5>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fas fa-minus"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn btn-tool"
                    @click="getIndex()"
                  >
                    <i class="fas fa-sync"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                    <label for="fecha_ini">Fecha Inicio</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filter.fecha_inicio"
                      style="font-size: 10px; background-color: #fff"
                    />
                  </div>
                  <div class="col-md-2">
                    <label for="fecha_fin">Fecha Final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filter.fecha_fin"
                      style="font-size: 10px; background-color: #fff"
                    />
                  </div>

                  <div class="col-md-4">
                    <label for="origen">Origen</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      placeholder="Origen"
                      v-model="origen"
                      label="nombre"
                      :options="listasForms.origenes"
                      @input="selectOrigen()"
                      style="font-size: 12px; background-color: #fff"
                      multiple
                    ></v-select>
                  </div>
                  <div class="col-md-4">
                    <label for="Destino">Destino</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      placeholder="Destino"
                      v-model="destino"
                      label="nombre"
                      :options="listasForms.destinos"
                      @input="selectDestino()"
                      style="font-size: 12px; background-color: #fff"
                      multiple
                    ></v-select>
                  </div>
                  <div class="col-md-4">
                    <label>Producto</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="productos"
                      placeholder="Nombre Producto"
                      label="nombre"
                      :options="listasForms.productos"
                      @input="seleccionarProducto()"
                      style="font-size: 10px; background-color: #fff"
                      multiple
                    ></v-select>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="empresa">Empresa</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        placeholder="Razon Social"
                        v-model="empresas"
                        label="razon_social"
                        :options="listasForms.empresas"
                        @input="selectEmpresa()"
                        style="font-size: 10px; background-color: #fff"
                        multiple
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fas fa-sort-amount-up-alt text-white"></i>
                      <b>Volumenes Totales</b>
                    </h3>
                  </div>

                  <div class="card-body">
                    <GraficaVolumenesDestinos ref="GraficaVolumenesDestinos" />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="card">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fa fa-truck text-white"></i>
                      <b>Cantidad Vehículos</b>
                    </h3>
                  </div>
                  <div class="card-body">
                    <GraficaVehiculos ref="GraficaVehiculos" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fa fa-money-check-alt text-white"></i>
                      <b>Suma de Costos Transportadoras</b>
                    </h3>
                  </div>
                  <div class="card-body">
                    <GraficaCostosTransporte ref="GraficaCostosTransporte" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fa fa-money-check-alt text-white"></i>
                      <b> Novedades Inspecciones por Empresa</b>
                    </h3>
                  </div>
                  <div class="card-body">
                    <GraficaNovedadesInspecciones
                      ref="GraficaNovedadesInspecciones"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fa fa-money-check-alt text-white"></i>
                      <b> Novedades Inspecciones por Item</b>
                    </h3>
                  </div>
                  <div class="card-body">
                    <GraficaNovedadesInspeccionesItem
                      ref="GraficaNovedadesInspeccionesItem"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg-frontera">
                    <h3 class="card-title text-white">
                      <i class="fa fa-money-check-alt text-white"></i>
                      <b> Novedades Inspecciones por Relevancia</b>
                    </h3>
                  </div>
                  <div class="card-body">
                    <GraficaNovedadesInspeccionesRelevancia
                      ref="GraficaNovedadesInspeccionesRelevancia"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import GraficaVolumenesNsv from "../dashboard/GraficaVolumenesNsv";
import GraficaVolumenesGsv from "../dashboard/GraficaVolumenesGsv";
import GraficaVolumenesDestinos from "../dashboard/GraficaVolumenesDestinos";
import GraficaVehiculos from "../dashboard/GraficaVehiculos";
import GraficaCostosTransporte from "../dashboard/GraficaCostosTransporte";
import GraficaNovedadesInspecciones from "../dashboard/GraficaNovedadesInspecciones";
import GraficaNovedadesInspeccionesItem from "../dashboard/GraficaNovedadesInspeccionesItem";
import GraficaNovedadesInspeccionesRelevancia from "../dashboard/GraficaNovedadesInspeccionesRelevancia";

export default {
  name: "GraficosIndex",
  components: {
    vSelect,
    GraficaVolumenesNsv,
    GraficaVolumenesGsv,
    GraficaVolumenesDestinos,
    GraficaVehiculos,
    GraficaCostosTransporte,
    GraficaNovedadesInspecciones,
    GraficaNovedadesInspeccionesItem,
    GraficaNovedadesInspeccionesRelevancia,
  },
  data() {
    return {
      filter: {
        origen_id: [],
        destino_id: [],
        empresa: [],
        producto: [],
        fecha_inicio: null,
        fecha_fin: null,
        año: "2021",
      },
      listasForms: {
        origenes: [],
        destinos: [],
        empresas: [],
        productos: [],
        años: [],
      },
      origen: null,
      destino: null,
      productos: null,
      empresas: null,
      respuesta: [],
      cargando: false,
    };
  },

  methods: {
    getIndex() {
      this.$refs.GraficaVehiculos.getGrafica();
      this.$refs.GraficaVolumenesDestinos.getGrafica();
      this.$refs.GraficaVolumenesGsv.getGrafica();
      this.$refs.GraficaVolumenesNsv.getGrafica();
      this.$refs.GraficaCostosTransporte.getGrafica();
      this.$refs.GraficaNovedadesInspecciones.getGrafica();
      this.$refs.GraficaNovedadesInspeccionesItem.getGrafica();
    },

    getEmpresa() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getOrigenes() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.origenes = response.data;
      });
    },

    getDestinos() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.destinos = response.data;
      });
    },

    getProductos() {
      axios.get("/api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productos = response.data;
      });
    },

    selectOrigen() {
      this.filter.origen_id = this.origen.map((e) => e.id);
    },

    selectDestino() {
      this.filter.destino_id = this.destino.map((e) => e.id);
    },

    selectEmpresa() {
      this.filter.empresa = this.empresas.map((e) => e.id);
    },

    seleccionarProducto() {
      this.filter.producto = this.productos.map((e) => e.id);
    },

    getAños() {
      axios.get("/api/lista/114").then((response) => {
        this.listasForms.años = response.data;
      });
    },
  },

  async mounted() {
    await this.getEmpresa();
    await this.getOrigenes();
    await this.getDestinos();
    await this.getProductos();
    await this.getAños();
    await this.getIndex();
  },
};
</script>
