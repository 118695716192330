<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
export default {
  name: "GraficaNovedadesInspeccionesRelevancia",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
        },
        title: {
          text: "",
          align: "center",
        },
        exporting: {
          enabled: true,
        },
        accessibility: {
          point: {
            valueSuffix: "",
          },
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y}</b>",
        },
        subtitle: {
          text: "CANTIDAD DE NOVEDADES POR RELEVANCIA",
          align: "center",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
          },
        },
        series: [
          {
            type: "pie",
            //data: [],
            data: [],
          },
        ],
      },
      respuesta: [],
    };
  },
  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get(
          "/api/hidrocarburos/dashboard/graficaNovedadesInspeccionesRelevancia",
          {
            params: this.$parent.filter,
          }
        )
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaNovedadesInspeccionesRelevancia();
        });
    },

    async graficaNovedadesInspeccionesRelevancia() {
      let relevante = 0;
      let no_relevante = 0;
      let categorias = [];
      relevante = this.respuesta.es_relevante;
      no_relevante = this.respuesta.no_relevante;
      this.chartOptions.series[0].data = [['relevante', relevante],["no_relevante",no_relevante]];
      this.chartOptions.series[0].name = "Novedades";
    },
  },

  mounted() {
    this.getGrafica();
  },
};
</script>
