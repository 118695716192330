<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
    <highcharts :options="chartOptions2"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
export default {
  name: "GraficaVolumenesDestinos",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],
        xAxis: {
          categories: [],
        },

        yAxis: {
          title: {
            text: "Cantidad de Barriles",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "SUMA BARRILES TRANSPORTADOS NSV POR DESTINO",
          align: "center",
        },

        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },

        series: [],

        credits: {
          enabled: false,
        },
      },
      chartOptions2: {
        colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],
        xAxis: {
          categories: [],
        },

        yAxis: {
          title: {
            text: "Cantidad de Barriles",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "SUMA BARRILES TRANSPORTADOS GSV POR DESTINO",
          align: "center",
        },

        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },

        series: [],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/hidrocarburos/dashboard/graficaVolumenesDestino", {
          params: this.$parent.filter,
        })
        .then(async (response) => {
          me.respuesta = await response.data;
          this.chartOptions.series = [];
          this.chartOptions2.series = [];

          await me.graficaVolumenesDestinos(me.respuesta);
        });
    },

    async graficaVolumenesDestinos() {
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      let categorias = [];
      let sumaGsvDescargue = [];
      let sumaNsvDescargue = [];
      let nsvCargue = [];
      let gsvCargue = [];

      for (let i = 0; i < this.respuesta.length; i++) {
        await categorias.push(this.respuesta[i].ndestino);
      }

      for (let i = categorias.length - 1; i >= 0; i--) {
        if (categorias.indexOf(categorias[i]) !== i)
          await categorias.splice(i, 1);
      }

      for (let m = 0; m < meses.length; m++) {
        sumaGsvDescargue[m] = [];
        sumaNsvDescargue[m] = [];
        nsvCargue[m] = [];
        gsvCargue[m] = [];

        for (let i = 0; i < categorias.length; i++) {
          await sumaGsvDescargue[m].push(0);
          await sumaNsvDescargue[m].push(0);
          await nsvCargue[m].push(0);
          await gsvCargue[m].push(0);
        }
      }

      for (let i = 0; i < this.respuesta.length; i++) {
        let idcat = await categorias.indexOf(this.respuesta[i].ndestino);

        if (this.respuesta[i].mes != 0 && this.respuesta[i].mes != null) {
          sumaNsvDescargue[this.respuesta[i].mes - 1][idcat] = parseFloat(
            this.respuesta[i].descargueNsv
          );

          nsvCargue[this.respuesta[i].mes - 1][idcat] = parseFloat(
            this.respuesta[i].cargueNsv
          );

          sumaGsvDescargue[this.respuesta[i].mes - 1][idcat] = parseFloat(
            this.respuesta[i].descargueGsv
          );

          gsvCargue[this.respuesta[i].mes - 1][idcat] = parseFloat(
            this.respuesta[i].cargueGsv
          );
        }
      }

      this.chartOptions.xAxis.categories = await categorias;

      for (let i = 0; i < meses.length; i++) {
        if (nsvCargue[i][0] > 0) {
          let objGraf = {
            data: nsvCargue[i],
            name: meses[i],
          };

          this.chartOptions.series[i] = objGraf;
        }
      }

      this.chartOptions2.xAxis.categories = await categorias;

      for (let i = 0; i < meses.length; i++) {
        if (gsvCargue[i][0] > 0) {
          let objGraf = {
            data: gsvCargue[i],
            name: meses[i],
          };

          this.chartOptions2.series[i] = objGraf;
        }
      }
    },
  },

  mounted() {
    this.getGrafica();
  },
};
</script>
