<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
import Highcharts from "highcharts";
export default {
  name: "GraficaCostosTransporte",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        colors: [
          "#ff1c79",
          "#001871",
          "#ba4477",
          "#4b3074",
          "#c84778",
          "#DB843D",
          "#828282",
          "#444444",
          "#000000",
        ],

        xAxis: {
          categories: [],
          title: {
            text: "Empresas Transportadoras",
          },
        },

        yAxis: {
          categories: [],
          title: {
            text: "Costos Transporte",
          },
          plotLines: [
            {
              // zero plane
              value: 0,
              color: "#BBBBBB",
              width: 1,
              zIndex: 10,
            },
          ],
          labels: {
            format: "${value}",
            style: {
              fontSize: "10px",
            },
            x: -3,
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "SUMA COSTOS TRANSPORTE",
          align: "center",
        },

        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },

        series: [],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/hidrocarburos/dashboard/getCostosTransporte", {
          params: this.$parent.filter,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          this.chartOptions.series = [];
          await me.graficaCostosTransporte(me.respuesta);
        });
    },

    async graficaCostosTransporte() {
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      let categorias = [];
      let costo = [];

      for (let i = 0; i < this.respuesta.length; i++) {
        categorias.push(this.respuesta[i].nTransportadora);
      }

      for (let i = categorias.length - 1; i >= 0; i--) {
        if (categorias.indexOf(categorias[i]) !== i) categorias.splice(i, 1);
      }

      for (let m = 0; m < meses.length; m++) {
        costo[m] = [];
        for (let i = 0; i < categorias.length; i++) {
          costo[m].push(0);
        }
      }

      for (let i = 0; i < this.respuesta.length; i++) {
        let idcat = categorias.indexOf(this.respuesta[i].nTransportadora);
        if (this.respuesta[i].mes != 0 && this.respuesta[i].mes != null) {
          costo[this.respuesta[i].mes - 1][idcat] = parseFloat(
            this.respuesta[i].costo
          );
        }
      }

      this.chartOptions.xAxis.categories = categorias;

      for (let i = 0; i < meses.length; i++) {
        if (costo[i][0] > 0) {
          let objGraf = {
            data: costo[i],
            name: meses[i],
            type: "column",
          };
          this.chartOptions.series[i] = objGraf;
        }
      }
    },
  },
  mounted() {
    this.getGrafica();
  },
};
</script>
